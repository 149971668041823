<template>
  <div class="details container">
    <div class="header_gruop page_width flex_sb">
      <div class="icon_home flex_center" @click="toBack()"><svg t="1729563443244" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="6080" id="mx_n_1729563443245" width="16" height="16"><path d="M398.912 513.152l377.28-384.256c27.904-28.416 27.904-74.432 0-102.784-27.84-28.352-73.024-28.352-100.8 0L247.808 461.504C233.792 475.776 226.88 494.464 226.944 513.152 226.88 531.84 233.792 550.464 247.808 564.736l427.584 435.392c27.84 28.352 73.024 28.352 100.8 0 27.904-28.352 27.904-74.368 0-102.656L398.912 513.152z" fill="#16181c" p-id="6081"></path></svg></div>
      <div class="header_title">{{ categoryName }}</div>
      <div></div>
      <!-- <div class="icon_shart" @click="share_drawer=true"><svg t="1729232411373" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="6045" width="18" height="18"><path d="M874.666667 544c-17.066667 0-32 14.933333-32 32v256c0 6.4-4.266667 10.666667-10.666667 10.666667H192c-6.4 0-10.666667-4.266667-10.666667-10.666667V192c0-6.4 4.266667-10.666667 10.666667-10.666667h256c17.066667 0 32-14.933333 32-32s-14.933333-32-32-32H192C151.466667 117.333333 117.333333 151.466667 117.333333 192v640c0 40.533333 34.133333 74.666667 74.666667 74.666667h640c40.533333 0 74.666667-34.133333 74.666667-74.666667V576c0-17.066667-14.933333-32-32-32z" fill="#ffffff" p-id="6046"></path><path d="M874.666667 117.333333H640c-17.066667 0-32 14.933333-32 32s14.933333 32 32 32h157.866667L509.866667 467.2c-12.8 12.8-12.8 32 0 44.8 6.4 6.4 14.933333 8.533333 23.466666 8.533333s17.066667-2.133333 23.466667-8.533333l285.866667-285.866667V384c0 17.066667 14.933333 32 32 32s32-14.933333 32-32V149.333333c0-17.066667-14.933333-32-32-32z" fill="#ffffff" p-id="6047"></path></svg></div> -->
    </div>
    <div class="details_container scoller grid_content" :v-infinite-scroll="false" style="overflow:auto">
      <div class="head_swiper">
          <!-- <swiper :options="swiperOption" ref="mySwiper" class="swiper_content">
              <swiper-slide class="slide_content" v-for="(i,index) in product.images" :key="index">
              <img :src="i" :data-src="i" alt="" />
          </swiper-slide>
          </swiper> -->
         
          <!-- <div class="swiper-pagination pagination_state" slot="pagination"></div> -->
          <img :src="product.image" alt="">
      </div>
      <div class="details_msg page_width">
        <div class="item_info">
          <div class="item_price flex">{{currency.remark}} {{ product.amount}} <div class="item_orgPrice" v-if="product.showAmount!=product.amount">{{currency.remark}} {{ product.showAmount }}</div></div>
          <div class="item_title">{{product.name}}</div>
        </div>
      </div>
      <div class="page_width details_msg">
        <div class="item_info">
          <div class="item_price" v-for="(i,index) in  product.detailStr.categoryNames" :key="index">{{ i }}</div>
        </div>
       
      </div>
      <div class="page_width" >
         <div class="items_list">
          <div class="items_list_thumb">
            <div class="items_title">{{ categoryName }}</div>
            <div class="items_content flex_warp">
              <div class="items_tab" :class="[attributes_id==item.id?'is_active':'']" v-for="(item,index) in category" :key="index" @click="selectItem(item.id,'stlect')">{{ item.name }}</div>
              
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="page_width" v-if="attributes">
        <div class="cardit flex_sb">
          <div class="cardit_tetx flex_center">Credit / Debit Card</div>
          <div class="cardid_img">
            <img src="https://bigplayers-global.oss-accelerate.aliyuncs.com/000002FMfhF2vE2Nb0000UBEWLOG000000Fy9?OSSAccessKeyId=LTAI5t7YSjpc7hnHgZvryrpf&Expires=1893427199&Signature=ILh2aFpJyyKk7pj0ZxXKJZFq6s8%3D" alt="">
          </div>
        </div>
      </div> -->
      <!-- <div class="page_width" v-if="attributes">
        <div class="item_details_thumb">
          <div class="details_thumb_tab">
            <el-tabs v-model="activeName">
              <el-tab-pane label="Product details" name="first"></el-tab-pane>
              <el-tab-pane label="Comment" name="second"></el-tab-pane>
            </el-tabs>
            <div class="first_container" v-show="activeName=='first'">
              <div class="text_p">Purchase Instructions</div>
              <div class="empty_line"></div>
           
                <div class="li_container"> <strong>Scope of Application:</strong>  </div>
                <div class="text_p">Mobile games + international servers</div>
                <div class="empty_line"></div>
                <div class="li_container"><strong>Required information:</strong></div>
                <div class="text_p">Player and server ID</div>
                <div class="empty_line"></div>
                <div class="li_container"><strong>First Reload:</strong></div>
                <div class="text_p">Double value on first reload</div>
                <div class="empty_line"></div>
              <div class="big_p">Method to verify the account ID</div>
              <img src="https://oss.ld-space.com/resource/mall/bd2e9mjm47n-1704442933.png" alt="">
              <img src="https://bigplayers-global.oss-accelerate.aliyuncs.com/000001Ibj882vE2Nb0000UMEpkzi000000Fy9?OSSAccessKeyId=LTAI5t7YSjpc7hnHgZvryrpf&Expires=1893427199&Signature=UgRv336VP%2BGf%2BoqQ%2B2MePQXA6rM%3D" alt="">
            </div>
            <div class="second_container" v-show="activeName=='second'">
              <img src="https://bigplayers-global.oss-accelerate.aliyuncs.com/000001yejIl2vE2Nb0000UNHawsE000000Fy9?OSSAccessKeyId=LTAI5t7YSjpc7hnHgZvryrpf&Expires=1893427199&Signature=UVSi6W1l2km21yIijRW1%2FDOgYJ8%3D" alt="">
            </div>
          </div>
        </div>
      </div> -->
      <div class="botton_empty"></div>
    </div>
   
    <div class="bottom_btn flex_center">
      <div class="htn_buy flex_center" @click="btnDrawer">Buy now</div>
    </div>
    <template>
      <el-drawer title="compartilhar.com" :visible.sync="share_drawer" :with-header="true" direction="btt" :modal-append-to-body="false" style="position:absolute" size="170px" :show-close="true">
        <div class="draw_container">
          <div class="share_link flex_col_center" @click="copyToClip">
            <div class="share_img">
              <img src="https://global-cdn.bigplayers.com/m/img/share_link.png?t=2024101819" alt="">
            </div>
            <div class="share_text" >copy link</div>
          </div>
        </div>
      </el-drawer>
    </template>
    <template>
      <el-drawer :visible.sync="buy_drawer"  direction="btt" :modal-append-to-body="false" :with-header="false" style="position:absolute" size="70%" :show-close="true">
        <div class="buy_container">
          <div class="buy_top flex">
            <div class="top_left">
              <img :src="product.image" alt="">
            </div>
            <div class="top_right flex_col_start">
              <!-- <div class="items_title">{{product.name }}</div> -->
              <div class="right_price flex">{{currency.remark}} {{ product.amount }} <div class="item_orgPrice" v-if="product.showAmount!=product.amount"> {{ product.showAmount }}</div></div>
              <div class="right_info">Selected: {{ attributes_name }}</div>
            </div>
          </div>
        </div>
        <div class="items_container">
          <div class="items_title">{{product.name }}</div>
          <div class="items_content flex_warp">
            <div class="items_tab" :class="[attributes_id==item.id?'is_active':'']"  v-for="(item,index) in category" :key="index" @click="selectItem(item.id)">{{ item.name }}</div>
            </div>
        </div>
        <div class="count_group flex_sb">
          <div class="count_title">Quantity</div>
          <div class="count_input">
            <el-input-number size="mini" :min="1" :max="999999"  v-model="count"></el-input-number>
          </div>
        </div>
        <div class="btn_buy">
          <div class="def_btn" @click="toPage('/checkout?goodsId='+product.id+'&count='+count)">Buy Now</div>
        </div>
      </el-drawer>
    </template>
  </div>
</template>

<script>
import { swiper, swiperSlide } from "vue-awesome-swiper";
import "swiper/dist/css/swiper.css";
import {divide} from '../../utils/tool'
import cookies from "../../utils/cookie";

export default {
  
  components:{
      swiper,
      swiperSlide,
      
  },
  data(){
    return{
      swiperOption: {
          loop: true,
          autoplay: {
          delay: 2000,
          stopOnLastSlide: false,
          disableOnInteraction: false,
          },
          freeMode: true,
          // 显示分页
          pagination: {
          el: ".swiper-pagination",
          type :'fraction',
          clickable: true, //允许分页点击跳转
          },
       
          
      },
      images:[
        {url:'https://bigplayers-global.oss-accelerate.aliyuncs.com/000000i8fDY2vE2Nb0000UMEpGyP000000Fy9?OSSAccessKeyId=LTAI5t7YSjpc7hnHgZvryrpf&Expires=1893427199&Signature=ielIjdmFqOUbsjdm69somuBTZnc%3D'},
        {url:'https://bigplayers-global.oss-accelerate.aliyuncs.com/000001cura72vE2Nb0000UMEpGug000000Fy9?OSSAccessKeyId=LTAI5t7YSjpc7hnHgZvryrpf&Expires=1893427199&Signature=mJQXZhuh67ymBziQXRTc0YtDCfI%3D'}
      ],
      defaultList:[
        
      ],
      activeName:'first',
      share_drawer:false,
      buy_drawer:false,
      count:1,
      goodsId:'',
      product:'',
      attributes:false,
      attributes_id:'',
      attributes_name:'',
      attributes_amount:'',
      attributes_image:'',
      categoryName:'',
      category:[],
      categoryImage:'',
      
    }
  },
  created(){
    let id = this.$route.query.id
    this.goodsId = id
    // this.getData()
    this.getCategory()
    this.getCategoryName()
  },
  methods:{
    toPage(path){
      this.$router.push(path)
    },
    copyToClip() {
      let address = window.location.href
      console.log('address',address)
      this.$clipboard(address)
      let obj={
        title:'Link copied successfully',
        message:'https://br.bigplayers.com/d/Honkai-Star-Rail-Comprar-Cristais?inviteCode=A0000275',
      }
      this.$store.commit('setDialogContent',obj)
      this.$store.commit('setDialogFlg',true)
    },
    toBack(){
      this.$router.back()
    },
    selectItem(id,type){
      if(type){
        this.buy_drawer = true
      }
      let category = this.category
      category.map(v=>{
        if(v.id == id){
          this.attributes_name = v.name
          this.attributes_id = v.id
          this.attributes_amount = v.amount
          this.product = v
        }
      })
    },
    btnDrawer(){
      let token = cookies.getToken()
      if(!token){
        this.$router.push('/login')
      }else{
        this.buy_drawer = true
      }
    },
    getData(){
      let goodsId = this.goodsId
      let language = this.lang.dictValue
      let _this = this
      if(!language||language==undefined){   
          setTimeout(()=>{
                _this.getData() 
          },1000)
          return
      }
      this.$axiosApi.getGoodsDetail({goodsId,language}).then(res=>{
          if(res.code == 200){
            let product=res.data
            let detailStr = JSON.parse(res.data.detailStr)
            let images = JSON.parse(res.data.images)
            product.detailStr = detailStr
            product.images = images
            product.amount =divide(res.data.amount) 
            product.showAmount =divide(res.data.showAmount) 
            if(product.detailStr.attributes.length > 0){
              this.attributes = true
              this.attributes_id = product.detailStr.attributes[0].id
              this.attributes_name = product.detailStr.attributes[0].name
              this.attributes_image = product.detailStr.attributes[0].values.image
            }
            this.goodsId = res.data.id
            this.product = product
              console.log('res',this.product)
          }
      })
    },
    getCategory(){
      let categoryId = this.goodsId
      let language = this.lang.dictValue
      let _this = this
      if(!language||language==undefined){   
          setTimeout(()=>{
                _this.getCategory() 
          },1000)
          return
      }
      this.$axiosApi.getGoodsDetailList({categoryId}).then(res=>{
          if(res.code == 200){
          
            res.data.map(v=>{
              v.detailStr = JSON.parse(v.detailStr)
              v.amount = divide(v.amount)
              v.showAmount = divide(v.showAmount)
              return v
            })
              let product=res.data[0]
              this.category = res.data
              this.product = product
              let attributes_id = res.data[0].id
              this.selectItem(attributes_id)
              console.log('res',this.product)
          }
      })
    },
    getCategoryName(){
      let defaultList = this.defaultList
      let categoryId = this.goodsId
      defaultList.map(v=>{
        if(v.categoryId == categoryId){
          this.categoryName = v.categoryName
          this.categoryImage = v.src
        }
      })
    }
  },
  computed:{
    defaultList(){return this.$store.state.defaultList},
    lang(){return this.$store.state.lang},
    currency(){return this.$store.state.currency}
  }
}
</script>

<style lang="scss" scoped> 
.details{
  height: 100%;
  position: relative;
}
.details_container{
  background:#f6f6f8;
  height: 100%;
  .head_swiper{
    position: relative;
    .swiper-pagination{
        width: 40px;
        padding:2px 4px;
        font-size: 12px;
        right:20px;
        bottom:10px;
        background:rgba(25,26,32,.7);
        border-radius: 10px;
        left: auto;
        color:#fff;
    }
  }
  .details_msg{
    margin:10px 0 5px;
    
    .item_info{
      background:#fff;
      border-radius: 8px;
      padding:10px 0;
      text-align: left;
      .item_price{
        color:#f77e1a;
        font-weight: bold;
        padding:6px 16px;
        align-items: baseline;
        .item_orgPrice{
          padding-left:6px;
          font-size: 12px;
          text-decoration: line-through;
          color:#909194;
        }
      }
      .item_title{
        color:#222425;
        font-size: 20px;
        font-weight: bold;
        padding:6px 16px;
      }
    }
  }
  .items_list{
    margin-top:5px;
    background:#fae44c;
    border-radius: 10px;
    padding:8px;
    .items_title{
      color:#16181f;
      padding:10px;
      font-size: 16px;
      text-align: left;
    }
    .items_content{
      border-radius: 4px;
      background:#fff;
      padding:8px;
      color:#4d4e4f;
      .items_tab{
        padding:12px 16px;
        background:#f6f6f8;
        font-size: 14px;
        margin:0 6px 12px 0;
        border-radius: 4px;
        cursor: pointer;
      }
    }
    .is_active{
      color:#f77e1a;
      border:1px solid #f77e1a;
    }
  }
  .cardit{
    border-radius: 10px;
    padding:0 12px;
    color:#161619;
    font-size: 16px;
    background:#fff;
    margin-top:10px;
    height: 60px;

  }
  .item_details_thumb{
    background:#fff;
    margin-top:6px;
    border-radius: 10px;
    text-align: left;
    color:#161619;
    .details_thumb_tab{
      padding:10px;
    }
    .li_container{
      
      color:#161619;
      font-size: 12px;
      position: relative;
      padding-left:10px;
      margin-bottom:10px;
      &::after{
        content:'';
        position: absolute;
        left:0;
        top:4px;
        height: 4px;
        width: 4px;
        background:#161619;
        border-radius: 2px;
        
      }
    }
    .text_p{
      font-size:12px;
    }
    .big_p{
      color:#0d0d0d;
      font-size: 26px;
      line-height: 1.6;
    }
    .empty_line{
      height: 20px;
    }
    /deep/.el-tabs__active-bar{
      background:#f77e1a;
      height: 4px;
      border-radius: 2px;
      width: 85px;
    }
    /deep/.el-tabs__item.is-active{
      color:#161619;
      font-weight: bold;

    }
    /deep/.el-tabs__item{
      color:#909194;
      padding:0 16px;
      &:hover{
       
      }
    }
    /deep/.el-tabs__nav-wrap{
      &:after{
       height: 0;
      }
    }
  }
}
.bottom_btn{
  position: absolute;
  bottom:0;
  left:0;
  right:0;
  // background:#fae44c;
  background: #fff;
  box-shadow: 0 .8vw 3.733vw .533vw rgba(0, 0, 0, .12);
  z-index: 999;
  padding:16px;
  .htn_buy{
    width:100%;
    background:#fae44c;
    color:#16181f;
    height:42px;
    border-radius: 20px;
  }
}
/deep/.el-drawer{
  // background:#fae44c;
  border-radius: 20px 20px 0 0 ;
  color:#16181c;
}

.share_link{
 width: 30%;
//  padding-bottom:42px;
 font-size: 14px;
 cursor: pointer;
 .share_img{
  width: 42px;
  height: 42px;
  margin-bottom:12px;
 }
}
.buy_container{
  padding:12px 14px;
  border-bottom:1px solid #ededf2;
  position: absolute;
  top:0;
  left:0;
  right:0;
  background:#fff;
  z-index: 9;
  .top_left{
    width:80px;
    height:80px;
    >img{
      width:100%;
      height: 100%;
    }
  }
  .top_right{
   margin-left:10px;
   padding-right:40px;
   text-align: left;
    .right_price{
      color:#f77e1a;
      font-size:14px;
      align-items: baseline;
      .item_orgPrice{
        padding-left:6px;
        font-size: 12px;
        text-decoration: line-through;
        color:#909194;
      }
    }
    .right_info{
      font-size: 12px;
      color:#222425;
    }
  }
}
.items_container{
  margin-top:105px;
  padding-top:12px;
  // border-bottom:1px solid #ededf2;
  .items_title{
    padding:0 0 4px 10px;
    font-size: 14px;
    text-align: left;
  }
  .items_content{
    padding:8px;
    .is_active{
      color:#f77e1a;
      border:1px solid #f77e1a;
    }
    .items_tab{
      padding:12px 16px;
      background:#f6f6f8;
      font-size: 14px;
      margin:0 6px 12px 0;
      border-radius: 4px;
      cursor: pointer;
    }
  }
}
.count_group{
  padding:12px 14px;
  margin-bottom:64px;
  // border-top:1px solid #ededf2;
  .el-input-number--mini{
    width:100px;
  }
  
}
.btn_buy{
  position: absolute;
  bottom:0;
  padding: 8px 16px;
  right:0;
  left:0;
  background:#fff;
  z-index: 9;
}

</style>